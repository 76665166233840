import { useEffect, useRef, useState } from "react";
import { setTitle } from "../../util/useDocumentTitle";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { addUpdateQuestion, fetchSurvey, saveSurveyStakeholder, selectSurvey } from "../../store/surveySlice";
import { Controller, FormProvider, SubmitHandler, useFieldArray, useForm, useWatch } from "react-hook-form";
import SurveyQuestion from "../../models/surveyQuestion";
import { api } from "../../store/api";
import { Typeahead } from "react-bootstrap-typeahead";

interface DetailsRouteParams {
  surveyId?: string;
  surveyQuestionId?: string;
}

export type NewOption = {
  customOption?: boolean;
  label?: string;
  id?: number;
}

export default function SurveyQuestionScreen() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams<keyof DetailsRouteParams>();
  const surveyState = useAppSelector(selectSurvey);
  const survey = surveyState.survey;
  const surveyQuestionId = params.surveyQuestionId && parseInt(params.surveyQuestionId);
  const question = survey?.questions?.find(q => q.id === surveyQuestionId);

  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string>();
  // const questions = survey?.questions; //?.filter(q => q.type === "MC");

  const [stickyY, setStickyY] = useState<number>();
  const refSticky = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (refSticky.current) {
      const boundingRect = refSticky.current.getBoundingClientRect();
      console.log('Sticky Rect', boundingRect);
      setStickyY(boundingRect.y);
    }
  }, [refSticky]);

  const [isScrollValueMoreThanHeaderHeight, setIsScrollValueMoreThanHeaderHeight] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (stickyY) {
        setIsScrollValueMoreThanHeaderHeight(window.scrollY >= stickyY);
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll);
  }, [stickyY]);

  useEffect(() => {
    if (params.surveyId) {
      const surveyId = parseInt(params.surveyId);
      if (!surveyState.isLoading && !surveyState.error) {
        if (survey === undefined || survey.id !== surveyId) {
          console.log('Fetching Survey', surveyId, survey);
          dispatch(fetchSurvey(surveyId));
        }
      }
    }
  }, [dispatch, params.surveyId, survey, surveyState.error, surveyState.isLoading]);

  const form = useForm<SurveyQuestion>();
  const { register, handleSubmit, reset, formState: { errors }, control, setValue } = form;
  const onSubmit: SubmitHandler<SurveyQuestion> = data => {
    // console.log(data);
    // console.log('selectedQuestionIds', selectedQuestionIds);
    const request: SurveyQuestion = {
      ...data,
      //questions: surveyState.survey?.questions?.filter(q => selectedQuestionIds.includes(q.id)),
    };

    setIsSaving(true);
    console.log(request);
    api.survey(survey!.id).questions(question!.id).update(request)
      .then((updated) => {
        setIsSaving(false);
        //setError(undefined);
        //const path = isEditing ? '' : '#created';
        //navigate(generatePath(ReviewDetailsRoute, { id: `${updated.id}` }) + path);
        dispatch(addUpdateQuestion(updated));
        navigate('../..');
      })
      .catch((reason) => {
        setError("Unable to save question: " + reason);
        setIsSaving(false);
      });

    // dispatch(saveSurveyDimension(survey!.id, request));
    // navigate('../..');
  };

  const { fields } = useFieldArray({
    name: "choices",
    keyName: "id",
    control
  });


  useEffect(() => {
    if (surveyState.isLoading) {
      setTitle(['Loading Survey Details...']);
    }
    else if (surveyState.survey && surveyQuestionId) {
      let titleParts = [surveyState.survey.name, 'Surveys'];
      const edit = survey?.questions?.find(d => d.id === surveyQuestionId);
      titleParts = ['Edit Question', ...titleParts];
      reset(edit);
      console.log('editing', edit);
      setTitle(titleParts);
    }
  }, [reset, survey?.questions, surveyQuestionId, surveyState.isLoading, surveyState.survey]);

  const watchDomain = useWatch({
    control: control,
    name: `domain`,
  });

  const watchIsReverseCoded = useWatch({
    control: control,
    name: `isReverseCoded`,
    defaultValue: question?.isReverseCoded
  });

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">Surveys</li>
        {surveyState.isLoading && <li className="breadcrumb-item">Loading...</li>}
        {surveyState.survey && <li className="breadcrumb-item"><Link to='../..'>{surveyState.survey.name}</Link></li>}
        <li className="breadcrumb-item">Questions</li>
        <li className="breadcrumb-item active">{question?.name}</li>
      </ol>
    </div>
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-3">
            {survey && <div className={"card border-0 mb-4 sticky-top " + (isScrollValueMoreThanHeaderHeight ? " shadow" : "")} ref={refSticky}>
              <div className="card-header bg-none h6 p-3 m-0 d-flex align-items-center">
                Edit Question for {survey && <i>{survey.name}</i>}
              </div>
              {survey && <div className="card-body row fw-bold">
                <div className="row mb-2">
                  <label className="form-label col-form-label col-12">Qualtrics ID</label>
                  <div className="col-12">
                    <input type="text" className={"form-control " + (errors.qualtricsId ? 'is-invalid' : '')} {...register("qualtricsId", { required: false })} autoComplete="off" readOnly disabled />
                    {errors.qualtricsId && <div className="invalid-feedback">This field is required</div>}
                  </div>
                </div>
                <div className="row mb-2">
                  <label className="form-label col-form-label col-12">Question Name</label>
                  <div className="col-12">
                    <textarea className={"form-control " + (errors.name ? 'is-invalid' : '')} {...register("name", { required: false })} autoComplete="off" readOnly disabled />
                    {errors.name && <div className="invalid-feedback">This field is required</div>}
                  </div>
                </div>
                <div className="row mb-2">
                  <label className="form-label col-form-label col-12">Question Text</label>
                  <div className="col-12">
                    <textarea className={"form-control " + (errors.text ? 'is-invalid' : '')} {...register("text", { required: false })} autoComplete="off" readOnly disabled />
                    {errors.text && <div className="invalid-feedback">This field is required</div>}
                  </div>
                </div>
                <div className="row mb-2">
                  <label className="form-label col-form-label col-12">Question Type</label>
                  <div className="col-12">
                    <input type="text" className={"form-control " + (errors.type ? 'is-invalid' : '')} {...register("type", { required: false })} autoComplete="off" readOnly disabled />
                    {errors.type && <div className="invalid-feedback">This field is required</div>}
                  </div>
                </div>
                <div className="row mb-2">
                  <label className="form-label col-form-label col-12">Coding</label>
                  <div className="col-12">
                    <div className="form-check form-switch mb-2">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" {...register(`isReverseCoded` as const, { valueAsDate: false })} />
                        {watchIsReverseCoded ? 'Reverse Coded' : 'Normally Coded'}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <label className="form-label col-form-label col-12">Domain</label>
                  <div className="col-12">
                    {/* <Controller
                      control={control}
                      name="domain"
                      rules={{ required: false }}
                      defaultValue={question?.domain}
                      //id="multiple-typeahead"
                      render={({ field, fieldState }) => (
                        <Typeahead
                          {...field}
                          //defaultInputValue={watchDomain ?? ''}
                          //newSelectionPrefix=''
                          //selected={[watchDomain ?? '']}
                          options={
                            survey.questions
                            ?.filter(q => q.domain?.length ?? 0)
                            .filter((value, index, array) => array.indexOf(value) === index) // unique
                            .map(q => q.domain!) ?? []
                          }
                          defaultInputValue={field.value}
                          allowNew={true}
                          emptyLabel='Select/add domain'
                          multiple={false}
                          clearButton={true}
                          //{...register(`domain` as const, { required: false })}
                          onChange={(selected) => {
                            console.warn('onChange', selected);
                            const selectedDomains = selected as string[];
                            //setSelected(selectedCustomers);
                            if (selectedDomains.length > 0) {
                              field.onChange(selectedDomains[0]);
                              //setValue("domain", selectedDomains[0]);
                            }
                            else {
                              field.onChange();
                              //setValue("domain", '');
                            }
                          }
                          }
                        />
                      )} /> */}
                    {/* <input type="text" className={"form-control " + (errors.domain ? 'is-invalid' : '')} {...register("domain", { required: false })} autoComplete="off" /> */}
                    <Typeahead
                      //className={"form-control " + (errors.domain ? 'is-invalid' : '')}
                      selected={watchDomain ? [watchDomain] : undefined}
                      clearButton={true}
                      onChange={(selected) => {
                        if (selected.length > 0) {

                          const newItem = selected[0] as NewOption;  
                          if (newItem.customOption) {
                            setValue('domain', newItem.label);
                          }
                          else {
                            const item = selected[0] as string;
                            console.log('domain', item);
                            setValue('domain', item);
                          }
                        }
                        else {
                          setValue('domain', undefined);
                        }
                      }}
                      options={[...new Set(survey.questions?.filter(q => q.domain)?.map((q) => q.domain!) ?? [])]}
                      allowNew={true}
                    />
                    {errors.domain && <div className="invalid-feedback">This field is required</div>}
                  </div>
                </div>
                <div className="row mb-2">
                  <label className="form-label col-form-label col-12">Sub-Domain</label>
                  <div className="col-12">
                    <input type="text" className={"form-control " + (errors.subDomain ? 'is-invalid' : '')} {...register("subDomain", { required: false })} autoComplete="off" />
                    {errors.subDomain && <div className="invalid-feedback">This field is required</div>}
                  </div>
                </div>
                <div className="row mb-2">
                  <label className="form-label col-form-label col-12">Comparison Label</label>
                  <div className="col-12">
                    <input type="text" className={"form-control " + (errors.comparisonLabel ? 'is-invalid' : '')} {...register("comparisonLabel", { required: false })} autoComplete="off" />
                  </div>
                </div>
              </div>}
            </div>}
          </div>
          <div className="col-md-9">
            {surveyState.error && <div className="alert alert-danger"><strong>Error!</strong> {surveyState.error}</div>}
            {fields.length > 0 && <div className="card">
              <div className="card-header">Choices</div>
              <table className="table">
                <thead>
                  <tr>
                    <th>Text</th>
                    <th>Recode</th>
                    <th>Numeric Value</th>
                    <th>Reverse Coded Value</th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map((field, index) => {
                    return <tr className="" key={index}>
                      <td>
                        <input
                          placeholder="choice text"
                          autoComplete="off" readOnly disabled
                          {...register(`choices.${index}.choiceText` as const, {
                            required: true
                          })}
                          className={"form-control form-control-sm " + (errors?.choices?.[index]?.choiceText ? "is-invalid" : "")} />
                      </td>
                      <td>
                        {field.recode}
                        {field.analyze === false && <span className="ms-2 badge badge-pill bg-warning">Not analyzed</span>}
                      </td>
                      <td>
                        <input
                          type="number"
                          placeholder=""
                          {...register(`choices.${index}.numericValue` as const, {
                            valueAsNumber: true,
                          })}
                          className={"form-control form-control-sm " + (errors?.choices?.[index]?.numericValue ? "is-invalid" : "")} />
                      </td>
                      <td>
                        <input
                          type="number"
                          placeholder=""
                          {...register(`choices.${index}.numericValueReversed` as const, {
                            valueAsNumber: true,
                          })}
                          className={"form-control form-control-sm " + (errors?.choices?.[index]?.numericValueReversed ? "is-invalid" : "")} />
                      </td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>}
          </div>
        </div>
        <div className="row">
          <div className="col">
            {error && <div className="alert alert-danger"><strong>Error</strong> {error}</div>}
            {isSaving ? <button type="submit" className="btn btn-success me-2" disabled={isSaving}>Saving Question...</button> : <button type="submit" className="btn btn-success me-2" disabled={isSaving}>Save Question</button>}
            <Link to='../..' className="btn btn-primary">Cancel</Link>
          </div>
        </div>
      </form>
    </FormProvider>

  </div>);
}
