import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import counterReducer from './counterSlice';
import themeReducer from './themeSlice';
import menuReducer from './menuSlice';
import labelsReducer from './labelsSlice';
/*import sessionReducer from './session/reducers';*/
import sessionReducer from './sessionSlice';
import uiReducer from './uiSlice';
import configReducer from './configSlice';
import surveyReducer from './surveySlice';
import { realtimeMiddleware } from '../realtime/middleware';
import { config } from '../config';

//const middleware = [...getDefaultMiddleware()];
//const excludeLoggerEnvs = ["test", "production"];
//const shouldIncludeLogger = !excludeLoggerEnvs.includes(
//    process.env.NODE_ENV || ""
//);

//if (shouldIncludeLogger) {
//    const logger = createLogger({
//        level: "info",
//        collapsed: true
//    });
//    middleware.push(logger);
//}
//instead of defining the reducers in the reducer field of configureStore, combine them here:
const rootReducer = combineReducers({ 
    counter: counterReducer,
    theme: themeReducer,
    menu: menuReducer,
    labels: labelsReducer,
    session: sessionReducer,
    ui: uiReducer,
    config: configReducer,
    survey: surveyReducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(realtimeMiddleware(config.realtimeUrl)),
});

store.subscribe(() => {
    localStorage.setItem('session', JSON.stringify(store.getState().session));
});
//export const configuredStore = (initialState?: RootState) => {
//    // Create Store
//    const store = configureStore({
//        reducer: rootReducer,
//        middleware,
//        preloadedState: initialState
//    });
//    replayActionRenderer(store);
//    store.subscribe(persistState(store));

//    if (process.env.NODE_ENV === "development" && module.hot) {
//        module.hot.accept(
//            "./rootReducer",
//            // eslint-disable-next-line global-require
//            () => store.replaceReducer(require("./rootReducer").default)
//        );
//    }
//    return store;
//};
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
