import { SurveyReportStakeholder } from "../../../models/surveyReportStakeholder";
import SurveyStakeholder from "../../../models/surveyStakeholder";

type PromptSurveyReportStakeholdersProps = {
  stakeholders: SurveyStakeholder[];
  values: SurveyReportStakeholder[];
  parentStakeholderId?: number;
  toggle: (stakeholder: SurveyStakeholder) => void;
  change: (stakeholder: SurveyStakeholder, value: string) => void;
}
export default function PromptSurveyReportStakeholders(props: PromptSurveyReportStakeholdersProps) {
  const stakeholders = props.stakeholders.filter((s) => s.parentSurveyStakeholderId === props.parentStakeholderId);
  const parentValue = props.values.find(v => v.id === props.parentStakeholderId);
  // console.log('render PromptSurveyReportStakeholders', props.parentStakeholderId);
  return <div key={`PromptSurveyReportStakeholders-${props.parentStakeholderId}`}>
    {stakeholders.map(s => {
      const value = props.values.find(v => v.id === s.id);
      const isDisabled = !(parentValue !== undefined ? parentValue.isEnabled : true);
      // ?? {
      //   isEnabled: false,
      //   minimumResponsesRequired: s.defaultMinimumResponsesRequired,
      //   surveyStakeholderId: s.id,
      // };
      const isEnabled = value?.isEnabled ?? false;
      return <div key={`Stakeholder-${s.id}`} className="row">
        <div className="row">
          <div className="form-check col-8">
            <input type="checkbox" className="form-check-input" checked={isEnabled} id={`surveyStakeholder_${s.id}`} onChange={() => props.toggle(s)} readOnly={isDisabled} disabled={isDisabled} />
            <label htmlFor={`surveyStakeholder_${s.id}`}>
              {s.name}
            </label>
          </div>
          <div className="col-4">
            <input type="number"
              className="form-control form-control-sm text-end"
              value={value?.minimumResponsesRequired ?? s.defaultMinimumResponsesRequired}
              onChange={(e) => props.change(s, e.currentTarget.value)}
              disabled={!isEnabled || isDisabled}
            />
          </div>
        </div>
        <div className="col-11 offset-1">
          <PromptSurveyReportStakeholders
            //key={`Sub-PromptSurveyReportStakeholders-${props.parentStakeholderId}`}
            parentStakeholderId={s.id}
            stakeholders={props.stakeholders}
            values={[...props.values]}
            toggle={props.toggle}
            change={props.change}
          />
        </div>
      </div>;
    })}
  </div>;
}