import { useEffect, useState } from "react";
import Paginated from "../../models/paginated";
import { format } from "../../helpers/format";

interface PaginationProps<T> {
  paginated: Paginated<T>;
  setPage: (page: number) => void;
  setPerPage: (page: number) => void;
}
export function Pagination<T>(props: PaginationProps<T>) {
  const { paginated, setPage, setPerPage } = props;
  const page = paginated.page;
  const perPage = paginated.perPage;
  const endRecordNumber = ((paginated.page - 1) * (paginated?.perPage ?? 0)) + (paginated?.records.length ?? 0);
  const totalRecords = paginated?.recordsTotal ?? 0;
  const [pageInput, setPageInput] = useState<string>(page.toString());

  useEffect(() => {
    setPageInput(page.toString());
  }, [page]);

  const changePage = () => {
    const newPage = parseInt(pageInput);
    if (newPage) {
      setPage(newPage);
    }
  };

  if (totalRecords <= endRecordNumber) {
    //return <></>;
  }

  return (<div className="card p-1 d-flex flex-row align-items-center justify-content-around">
    <div>
      {(totalRecords >= endRecordNumber) && <span>{format.number(1 + ((paginated.page - 1) * (paginated?.perPage ?? 0)))} &ndash; {format.number(endRecordNumber)} of</span>} {format.number(paginated?.recordsTotal ?? 0)} total
    </div>
    <div className="d-flex flex-row align-items-center">
      <div className="input-group input-group-sm">
        <span className="input-group-text" id="inputGroup-sizing-sm">Page</span>
        <button className="btn btn-outline-secondary btn-sm" disabled={page <= 1} onClick={() => setPage(page - 1)}><i className="fa fa-angle-left"></i></button>
        <input type="text" min={1} step={1} className="form-control text-center w-50px"
          value={pageInput}
          onChange={(e) => setPageInput(e.currentTarget.value)}
          onBlur={changePage}
          onKeyUp={(e) => {
            if (e.key == 'Enter') {
              changePage();
            }
          }}
        />
        <button className="btn btn-outline-secondary btn-sm" disabled={endRecordNumber >= totalRecords} onClick={() => setPage(page + 1)}><i className="fa fa-angle-right"></i></button>
      </div>
    </div>
    <div className="d-flex flex-row align-items-center">
      <div className="input-group input-group-sm">
        <span className="input-group-text" id="inputGroup-sizing-sm">Rows per page:</span>
        <select value={perPage} className="form-control form-control-sm" onChange={(e) => setPerPage(parseInt(e.currentTarget.value))}><option>10</option><option>50</option><option>100</option></select>
      </div>
    </div>
  </div>);
}