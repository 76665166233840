import { useCallback, useEffect, useState } from "react";
import { Link, createSearchParams, generatePath, useLocation, useParams } from "react-router-dom";
import EntityTypes from "../../models/entityTypes";
import Project from "../../models/project";
import { SearchProps, api } from "../../store/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import { ProjectEditRoute } from "./projectEditScreen";
import { PartnerDetailsRoute } from "../partners/partnerDetailsScreen";
import { ProjectResourceList } from "../../components/project/projectResourceList";
import RecordDetailsCard from "../../components/records/recordDetailsCard";
import { ProjectResourcesRoute } from "./projectResourcesScreen";
import { ProjectResourceSummary } from "../../components/project/projectResourceSummary";
import ProjectGrade from "../../models/projectGrade";
import EntityTypeLabel from "../../components/label/entityTypeLabel";
import { setProjectDetailsTab } from "../../store/uiSlice";
import RecordsList, { RecordFilter } from "../../components/records/recordsList";
import GradeTypes from "../../models/gradeTypes";
import ProjectReviewer from "../../models/projectReviewer";
import AddProjectReviewer from "../../components/project/addProjectReviewer";
import { format } from "../../helpers/format";
import { ReviewDetailsRoute } from "../reviews/reviewDetailsScreen";
import KnowledgeMapTypes from "../../models/knowledgeMapTypes";
import ProjectResource from "../../models/projectResource";
import { ReviewsCreateRoute } from "../reviews/reviewsScreen";
import { setTitle } from "../../util/useDocumentTitle";
import { TagTree } from "../../components/tags/tagTree";
import { UnitEvaluationsCreateRoute } from "../unitEvaluations/unitEvaluationsScreen";
import { UnitEvaluationsDetailsRoute } from "../unitEvaluations/unitEvaluationsDetailsScreen";
import { RequiresPermission } from "../../components/role/requiresRole";
import { PermissionTypes } from "../../models/permissionTypes";
import { usePermissions } from "../../util/usePermissions";
import { ProjectFlatFileRoute } from "./projectFlatFile";
import { UnitReportRoute } from "../reports/unitReportScreen";
import { HeatmapReportRoute } from "../reports/heatMapScreen";
import { ResourceQualityReportRoute } from "../reports/resourceQualityReport";
import { CoherencyReportRoute } from "../reports/coherencyReport";
import { ProjectShareRoute } from "./projectShareScreen";
import { ReviewDeleteRoute } from "../reviews/reviewDeleteScreen";


export const ProjectDetailsRoute = "/projects/:id";

interface ProjectDetailsRouteParams {
  id?: string;
}

export default function ProjectDetailsScreen() {
  const labels = useAppSelector(selectLabels);
  const ui = useAppSelector(state => state.ui.projectDetails);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [record, setRecord] = useState<Project>();
  // const [contacts, setContacts] = useState<Contact[] | undefined>();
  // const [leases, setLeases] = useState<Project[] | undefined>();
  const [error, setError] = useState<String>();

  const { hasPermission } = usePermissions();

  const params = useParams<keyof ProjectDetailsRouteParams>();
  const [projectGrades, setProjectGrades] = useState<ProjectGrade[] | undefined>(undefined);
  const [grades, setGrades] = useState<GradeTypes[]>([]);
  const [selectedGrade, setSelectedGrade] = useState<GradeTypes>();
  const [showAddReviewer, setShowAddReviewer] = useState(false);
  const [sortReviewers, setSortReviewers] = useState<RecordFilter<ProjectReviewer>>();

  const getGrades = useCallback(() => {
    console.log('getGrades();');
    api.projects.getGrades(parseInt(params.id!))
      .then((result) => {
        console.log('updating grades');
        setProjectGrades(result);
        setGrades(result.map(r => r.grade));
      })
      .catch((reason) => {
        console.error(reason);
        setError("Unable to load " + labels.project.singular + ` grade information #${params.id}`);
      });
  }, [labels.project.singular, params.id]);

  // navigate to the tab for the URL hash e.g. #tag
  useEffect(() => {
    if (location.hash) {
      switch (location.hash) {
        case '#resources':
          dispatch(setProjectDetailsTab({ tab: EntityTypes.ProjectResource }))
          break;
        case '#todo':
          dispatch(setProjectDetailsTab({ tab: EntityTypes.User }))
          break;
        case '#tag':
          dispatch(setProjectDetailsTab({ tab: EntityTypes.Tag }))
          break;
      }
    }
  }, [dispatch, location.hash]);

  useEffect(() => {
    // Load the attribute and set the form with the current values
    setIsLoading(true);
    api.projects.get(parseInt(params.id!))
      .then((record) => {
        setRecord(record);
        setTitle([`${labels.project.singular} Details`, record.name!]);
        setSortReviewers({ key: 'all', label: 'All/Any', api: (search: SearchProps) => api.reviewers.forProject({ ...search, projectId: record.id ?? 0, }) },);
      })
      .catch((reason) => {
        console.error(reason);
        setError("Unable to load " + labels.project.singular + ` #${params.id}`);
      });

    getGrades();

  }, [getGrades, labels.contact.plural, labels.project.singular, params.id]);

  const onAddReviewers = (grade: GradeTypes) => {
    dispatch(setProjectDetailsTab({ tab: EntityTypes.Reviewer }));
    setShowAddReviewer(true);
    setSelectedGrade(grade);
  };

  const onReviewerAdded = (e: ProjectReviewer): void => {
    setSortReviewers({ key: 'recent', label: 'Recently Added', api: (search: SearchProps) => api.reviewers.forProject({ ...search, projectId: record!.id!, }) });
    getGrades();
  }

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <div>
        <h1 className="page-header mb-0">
          {record?.name} {record?.isActive === false && <span className="badge bg-danger">DISABLED</span>}
        </h1>
        <ul className="breadcrumb">
          <li className="breadcrumb-item"><Link to={generatePath(PartnerDetailsRoute, { id: `${record?.partner?.id}` })}>{record?.partner?.name}</Link></li>
          <li className="breadcrumb-item active">{labels.project.singular} Details</li>
        </ul>
      </div>
    </div>
    {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
    <div className="row">
      <div className="col-md-4">
        {record && <RecordDetailsCard
          record={record}
          type={EntityTypes.Project}
          editRoute={ProjectEditRoute}
          footer={<div className="d-flex flex-row justify-content-end">
            <RequiresPermission permission={PermissionTypes.ProjectEdit}>
              <div>
                <button className="btn btn-white dropdown-toggle" type="button" data-bs-toggle="dropdown">Reports <b className="caret"></b></button>
                <div className="dropdown-menu">
                  <Link to={{ pathname: UnitReportRoute, search: `?project=${record?.id}` }} className="dropdown-item">Unit Report</Link>
                  <Link to={{ pathname: HeatmapReportRoute, search: `?project=${record?.id}` }} className="dropdown-item">Heat Map Report</Link>
                  <Link to={{ pathname: ResourceQualityReportRoute, search: `?project=${record?.id}` }} className="dropdown-item">Resource Quality Report</Link>
                  <Link to={{ pathname: CoherencyReportRoute, search: `?project=${record?.id}` }} className="dropdown-item">Coherency Report</Link>
                </div>
              </div>
              <div className="ms-auto text-decoration-none">
                <button className="btn btn-white dropdown-toggle" type="button" data-bs-toggle="dropdown">Actions <b className="caret"></b></button>
                <div className="dropdown-menu">
                  <Link to={generatePath(ProjectShareRoute, { id: `${record?.id}` })} className="dropdown-item"><i className="fa fa-link fa-lg me-2 ms-n2"></i> Share</Link>
                  <Link to={generatePath(ProjectFlatFileRoute, { id: `${record?.id}` })} className="dropdown-item"><i className="fa fa-download fa-lg me-2 ms-n2"></i> Export</Link>
                </div>
              </div>
            </RequiresPermission>
          </div>}
          displayAttributes={[
            { label: labels.partner.singular, callback: (record: Project) => <Link to={generatePath(PartnerDetailsRoute, { id: `${record?.partnerId}` })}>{record?.partner?.name}</Link>, },
            { label: 'Knowledge Map Type', callback: (record: Project) => <>{labels.knowledgeMapTypes[record.knowledgeMapType ?? KnowledgeMapTypes.Undefined]}</>, },
            { label: labels.unitevaluation.plural, callback: (record: Project) => <>{record.unitEvaluations === true ? 'Yes' : 'No'}</>, },
          ]}
        />}

        {(record && projectGrades) && <div className="card border-0 mb-4">
          <div className="card-header bg-none p-3 h6 m-0 d-flex align-items-center">
            {labels.projectResource.singular} Summary
            <RequiresPermission permission={PermissionTypes.ProjectEdit}>
              <Link to={generatePath(ProjectResourcesRoute, { projectId: `${record?.id}` })} className="ms-auto text-decoration-none text-gray-500"><i className="fa fa-edit fa-lg me-2 ms-n2"></i> Add</Link>
            </RequiresPermission>
          </div>
          <div className="card-body fw-bold">
            <ProjectResourceSummary project={record} projectSummary={projectGrades} onAddReviewers={onAddReviewers} />
          </div>
        </div>}
      </div>
      <div className="col-md-8">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button type="button" className={"nav-link " + (ui.tab === EntityTypes.Tag ? "active" : "")} aria-current="page" onClick={(e) => dispatch(setProjectDetailsTab({ tab: EntityTypes.Tag }))}>{labels.tag.plural}</button>
          </li>
          <li className="nav-item">
            <button type="button" className={"nav-link " + (ui.tab === EntityTypes.ProjectResource ? "active" : "")} aria-current="page" onClick={(e) => dispatch(setProjectDetailsTab({ tab: EntityTypes.ProjectResource }))}>{labels.projectResource.plural}</button>
          </li>
          <RequiresPermission permission={PermissionTypes.ProjectReviewersList}>
            <li className="nav-item">
              <button type="button" className={"nav-link " + (ui.tab === EntityTypes.Reviewer ? "active" : "")} onClick={(e) => dispatch(setProjectDetailsTab({ tab: EntityTypes.Reviewer }))}>Assigned <EntityTypeLabel entityType={EntityTypes.Reviewer} /></button>
            </li>
          </RequiresPermission>
          <RequiresPermission permission={[PermissionTypes.ProjectReviewsListMine, PermissionTypes.ProjectReviewsListAll]}>
            <li className="nav-item">
              <button type="button" className={"nav-link " + (ui.tab === EntityTypes.Review ? "active" : "")} onClick={(e) => dispatch(setProjectDetailsTab({ tab: EntityTypes.Review }))}><EntityTypeLabel entityType={EntityTypes.Review} /></button>
            </li>
          </RequiresPermission>
          {record?.unitEvaluations && <li className="nav-item">
            <button type="button" className={"nav-link " + (ui.tab === EntityTypes.UnitEvaluation ? "active" : "")} onClick={(e) => dispatch(setProjectDetailsTab({ tab: EntityTypes.UnitEvaluation }))}><EntityTypeLabel entityType={EntityTypes.UnitEvaluation} /></button>
          </li>}
          <li className="nav-item">
            <button type="button" className={"nav-link " + (ui.tab === EntityTypes.User ? "active" : "")} onClick={(e) => dispatch(setProjectDetailsTab({ tab: EntityTypes.User }))}>To Do</button>
          </li>
        </ul>
        {ui.tab === EntityTypes.UnitEvaluation && record?.unitEvaluations &&
          <div className="card border-0 mb-4">
            {record?.id &&
              <RecordsList
                type={EntityTypes.UnitEvaluation}
                showName={false}
                filters={
                  [
                    { key: 'all', label: 'Any/All', api: (search: SearchProps) => api.unitEvaluations.forProject({ ...search, projectId: record!.id!, }) },
                    // grades.map(grade => {
                    //   label: `Grade ${GradeTypes[grade]}`,
                    //   api: (term?: string, page?: number, perPage?: number) => api.reviewers.forProject(record!.id!, term, page, perPage, grade),
                    // }),
                  ]
                }
                columns={[
                  // {
                  //   label: labels.resource.singular,
                  //   callback: (record) => <td>{record.resourceName}</td>,
                  // },
                  {
                    label: labels.reviewer.singular,
                    sortKey: 'user',
                    callback: (record) => <td>{record.reviewer}</td>,
                  },
                  {
                    label: 'Grade',
                    sortKey: 'grade',
                    callback: (record) => <td>{labels.grades[record.gradeUnit?.grade ?? GradeTypes.NA]}</td>,
                  },
                  {
                    label: 'Unit',
                    sortKey: 'unit',
                    callback: (record) => <td>{record.gradeUnit?.unit}</td>,
                  },
                  {
                    label: 'Created',
                    sortKey: 'created',
                    callback: (record) => <td>{format.ago(record.createdUtc!)}</td>,
                  },
                ]}
                actions={(record) => <>
                  <Link key={`review-${record.id}`} className="btn btn-sm btn-outline-primary" to={{ pathname: generatePath(UnitEvaluationsDetailsRoute, { id: record.id!.toString() }) }}>Details</Link>
                  {/* <Link key={`createReview-${record.id}`} className="btn btn-sm btn-outline-primary" to={{ pathname: generatePath(ReviewsCreateRoute), search: createSearchParams({ projectResourceId: `${record.id}` }).toString() }}>Start <EntityTypeLabel entityType={EntityTypes.Review} singlular /></Link> */}
                </>}
              />}
          </div>
        }
        {ui.tab === EntityTypes.ProjectResource &&
          <div className="card border-0 mb-4">
            {record?.id && <ProjectResourceList projectId={record.id} grades={grades} />}
            <RequiresPermission permission={PermissionTypes.ProjectResourcesCreate}>
              <div className="card-footer text-end">
                <Link to={generatePath(ProjectResourcesRoute, { projectId: `${record?.id}` })} className="ms-auto btn btn-outline-primary"><i className="fa fa-plus fa-lg me-2 ms-n2"></i> Add {labels.projectResource.singular}</Link>
              </div>
            </RequiresPermission>
          </div>
        }
        {ui.tab === EntityTypes.Reviewer && record &&
          <RequiresPermission permission={PermissionTypes.ProjectReviewersList}>
            <div className="card border-0 mb-4">
              {sortReviewers &&
                <RecordsList type={EntityTypes.Reviewer} filters={[
                  sortReviewers,
                  ...grades.map<RecordFilter<ProjectReviewer>>((val, idx, arr) => Object.assign({
                    key: `grade-${val}`,
                    label: 'Grade ' + labels.grades[val],
                    api: (search: SearchProps) => api.reviewers.forProject({ ...search, projectId: record!.id!, grade: val.toString() })
                  }))
                ]}
                  actions={(record: ProjectReviewer) =>
                    <button className="btn btn-sm btn-outline-danger" title="Remove" onClick={(e) => {
                      api.reviewers.update(Object.assign(record, { projectId: record?.id, isActive: false }))
                        .then(() => {
                          onReviewerAdded(record);
                        });
                    }}><i className="fas fa-times"></i></button>
                  }
                  columns={[
                    // {
                    //   label: "Active",
                    //   callback: (record: ProjectReviewer) => record.isActive ? <td>Active</td> : <td><span className="badge bg-danger">DISABLED</span></td>
                    // },
                    {
                      label: "Grade",
                      headerClassNames: 'text-end',
                      callback: (record: ProjectReviewer) => <td className="text-end">{labels.grades[record.grade!]}</td>
                    },
                    //resourcesToReview
                    {
                      label: labels.review.plural,
                      headerClassNames: 'text-end',
                      callback: (record: ProjectReviewer) => <td className="text-end">{record.resourcesReviewed} of {record.resourcesToReview}</td>
                    },
                    {
                      label: `To ${labels.review.singular}`,
                      headerClassNames: 'text-end',
                      callback: (record: ProjectReviewer) => <td className="text-end">{(record.resourcesToReview ?? 0) - (record.resourcesReviewed ?? 0)}</td>
                    },
                    //unitEvaluations
                    {
                      label: `${labels.unitevaluation.plural}`,
                      headerClassNames: 'text-end',
                      callback: (projectReviewer: ProjectReviewer) => record.unitEvaluations === true ? <td className="text-end">{projectReviewer.unitsEvaluated}</td> : <td className="text-end">N/A</td>
                    },
                  ]}
                />}
              {showAddReviewer
                ? <div className="card-footer"><AddProjectReviewer project={record!} selectedGrade={selectedGrade} grades={grades} onAdded={onReviewerAdded} /></div>
                :
                <div className="card-footer text-end">
                  <button className="btn btn-outline-primary ms-auto" onClick={(e) => setShowAddReviewer(true)}><i className="fa fa-plus fa-lg me-2 ms-n2"></i> Assign {labels.reviewer.plural}</button>
                </div>}
            </div>
          </RequiresPermission>
        }
        {ui.tab === EntityTypes.Review &&
          <RequiresPermission permission={PermissionTypes.ProjectReviewsListMine}>
            <div className="card border-0 mb-4">
              {record?.id &&
                <RecordsList
                  type={EntityTypes.Review}
                  showName={false}
                  filters={
                    hasPermission(PermissionTypes.ProjectReviewsListAll) ?
                      [
                        { key: 'all', label: 'All', api: (search: SearchProps) => api.reviews.forProject({ ...search, projectId: record!.id!, }) },
                        { key: 'mine', label: 'Submitted by me', api: (search: SearchProps) => api.reviews.forProject({ ...search, projectId: record!.id!, mine: true, }) },
                      ]
                      : [
                        { key: 'mine', label: 'Submitted by me', api: (search: SearchProps) => api.reviews.forProject({ ...search, projectId: record!.id!, mine: true, }) },
                      ]
                  }
                  columns={[
                    {
                      label: labels.resource.singular,
                      callback: (record) => <td>{record.resourceName}</td>,
                    },
                    {
                      label: labels.reviewer.singular,
                      sortKey: 'user',
                      callback: (record) => <td>{record.userName}</td>,
                    },
                    {
                      label: 'Created',
                      sortKey: 'created',
                      callback: (record) => <td>{format.ago(record.createdUtc!)}</td>,
                    },
                  ]}
                  actions={(record) => <>
                    <Link key={`review-${record.id}`} className="btn btn-sm btn-outline-primary" to={{ pathname: generatePath(ReviewDetailsRoute, { id: record.id!.toString() }) }}>Details</Link>
                    <RequiresPermission permission={PermissionTypes.ReviewDelete}>
                      <Link key={`review-${record.id}`} className="mx-1 btn btn-sm btn-outline-danger" to={{ pathname: generatePath(ReviewDeleteRoute, { id: record.id!.toString() }) }}>Delete</Link>
                    </RequiresPermission>
                    {/* <Link key={`createReview-${record.id}`} className="btn btn-sm btn-outline-primary" to={{ pathname: generatePath(ReviewsCreateRoute), search: createSearchParams({ projectResourceId: `${record.id}` }).toString() }}>Start <EntityTypeLabel entityType={EntityTypes.Review} singlular /></Link> */}
                  </>}
                />}
            </div>
          </RequiresPermission>
        }
        {ui.tab === EntityTypes.Tag && record &&
          <div className="card border-0 mb-4">
            <div className="card-body">
              {(record!.tags?.length ?? 0) === 0 && <div className="alert alert-warning">No {labels.tag.plural} have been associated with this {labels.project.singular}. Add some from the Available {labels.tag.plural} on the Edit screen.</div>}
              <TagTree
                selectedTagIds={record!.tags?.map(t => t.tagId) ?? []}
              />
            </div>
          </div>
        }
        {ui.tab === EntityTypes.User && record && (record?.unitEvaluations ?? false) &&
          <RequiresPermission permission={[PermissionTypes.ProjectUnitEvaluationsListMine, PermissionTypes.ProjectUnitEvaluationsListAll]}>
            <RecordsList
              type={EntityTypes.UnitEvaluation}
              showName={false}
              showSearch={false}
              showIfEmpty={false}
              showAttributes={false}
              filters={[
                { key: 'all', label: 'All To Do', api: (search: SearchProps) => api.todo.unitEvaluations({ ...search, projectId: record!.id!, }) },
              ]}
              columns={[
                {
                  label: labels.unitevaluation.singular,
                  callback: (record) => <td>Completed {labels.review.singular} of all {labels.resource.plural}</td>,
                },
                {
                  label: 'Grade',
                  callback: (record) => <td>{labels.grades[record.grade ?? GradeTypes.NA]}</td>,
                },
                {
                  label: 'Unit',
                  callback: (record) => <td>{record.unit}</td>,
                },
              ]}
              actions={(record) => <>
                <Link key={`createUnitLevelReview-${record.id}`} className="btn btn-sm btn-outline-primary" to={{ pathname: generatePath(UnitEvaluationsCreateRoute), search: createSearchParams({ gradeUnitId: `${record.id}` }).toString() }}>Start {labels.unitevaluation.singular} for Grade {labels.grades[record.grade ?? GradeTypes.NA]} Unit {record.unit}</Link>
              </>}
            />
          </RequiresPermission>
        }
        {ui.tab === EntityTypes.User && record &&
          record?.id &&
          <RecordsList
            type={EntityTypes.Resource}
            showName={false}
            showAttributes={false}
            filters={[
              { key: 'all', label: 'All To Do', api: (search: SearchProps) => api.todo.forProject({ ...search, projectId: record!.id!, }) },
              ...grades.map<RecordFilter<ProjectResource>>((val, idx, arr) => Object.assign({
                key: `grade-${val}`,
                label: 'Grade ' + labels.grades[val],
                api: (search: SearchProps) => api.todo.forProject({ ...search, projectId: record!.id!, grade: val.toString() })
              }))
            ]}
            columns={[
              {
                label: labels.resource.singular,
                callback: (record) => <td>{record.resource?.name}</td>,
              },
              {
                label: 'Grade',
                callback: (record) => <td>{labels.grades[record.gradeUnit?.grade ?? GradeTypes.NA]}</td>,
              },
              {
                label: 'Unit',
                callback: (record) => <td>{record.gradeUnit?.unit}</td>,
              },
            ]}
            actions={(record) => <>
              <Link key={`createReview-${record.id}`} className="btn btn-sm btn-outline-primary" to={{ pathname: generatePath(ReviewsCreateRoute), search: createSearchParams({ projectResourceId: `${record.id}` }).toString() }}>Start <EntityTypeLabel entityType={EntityTypes.Review} singlular /></Link>
              {/* <Link key={`createReview-${record.id}`} className="btn btn-sm btn-outline-primary" to={{ pathname: generatePath(ReviewsCreateRoute), search: createSearchParams({ projectResourceId: `${record.id}` }).toString() }}>Start <EntityTypeLabel entityType={EntityTypes.Review} singlular /></Link> */}
            </>}
          />}
      </div>
    </div>
  </div>);
}
