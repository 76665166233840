import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import EntityTypes, { EntityTypeKeys } from '../models/entityTypes';
import { SurveyDetailsTabs } from '../screens/surveys/surveyDetailsScreen';

export interface TabState {
    tab: EntityTypes;
}
export interface RecordListState {
    selectedFilter: string;
}
export interface UiState {
    projectDetails: TabState;
    resourceDetails: TabState;
    recordList: { [n in EntityTypes as number]?: RecordListState };
    review: TabState;
    survey: {
        detailsTab: SurveyDetailsTabs,
    }
}

const initialState: UiState = {
    projectDetails: {
        tab: EntityTypes.User,
    },
    resourceDetails: {
        tab: EntityTypes.Review,
    },
    recordList: {},
    review: {
        tab: EntityTypes.Tag,
    },
    survey: {
        detailsTab: SurveyDetailsTabs.SharedReports,
    },
};

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setResourceDetailsTab: (state, action: PayloadAction<TabState>) => {
            state.resourceDetails = {
                ...action.payload
            };
        },
        setProjectDetailsTab: (state, action: PayloadAction<TabState>) => {
            state.projectDetails = {
                ...action.payload
            };
        },
        setReviewTab: (state, action: PayloadAction<TabState>) => {
            state.review = {
                ...action.payload
            };
        },
        setRecordListFilter: {
            reducer: (state, action: PayloadAction<{ entity: EntityTypes, filterKey: string }>) => {
                state.recordList[action.payload.entity] = {
                    ...state.recordList[action.payload.entity],
                    selectedFilter: action.payload.filterKey,
                };
            },
            prepare: (type: EntityTypes, filterKey: string) => {
                return {
                    payload: {
                        entity: type,
                        filterKey,
                    }
                };
            }
        },
        setSurveyDetailsTab: (state, action: PayloadAction<SurveyDetailsTabs>) => {
            return {
                ...state,
                survey: {
                    ...state.survey,
                    detailsTab: action.payload,
                },
            };
        },
    },
});

export const { setResourceDetailsTab, setProjectDetailsTab, setRecordListFilter, setReviewTab, setSurveyDetailsTab } = uiSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUi = (state: RootState) => state.ui;

export default uiSlice.reducer;
