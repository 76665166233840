import { useEffect, useRef, useState } from "react";
import { setTitle } from "../../util/useDocumentTitle";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fetchSurvey, saveSurveyStakeholder, selectSurvey } from "../../store/surveySlice";
import { FormProvider, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import SurveyStakeholder from "../../models/surveyStakeholder";
import { Typeahead, TypeaheadRef } from "react-bootstrap-typeahead";
import SurveyQuestion from "../../models/surveyQuestion";
import { format } from "../../helpers/format";
import BooleanLogicOperationTypes from "../../models/booleanLogicOperationTypes";

interface DetailsRouteParams {
  surveyId: string;
  surveyStakeholderId: string;
}

export default function SurveyStakeholderDetailsScreen() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams<keyof DetailsRouteParams>();
  const surveyState = useAppSelector(selectSurvey);
  const survey = surveyState.survey;

  const [stakeholder, setStakeholder] = useState<SurveyStakeholder>();

  useEffect(() => {
    if (params.surveyId) {
      const surveyId = parseInt(params.surveyId);
      if (!surveyState.isLoading) {
        if (survey === undefined || survey.id !== surveyId) {
          console.log('Fetching Survey', surveyId, survey);
          dispatch(fetchSurvey(surveyId));
        }
      }
    }
  }, [dispatch, params.surveyId, survey, surveyState.isLoading]);

  useEffect(() => {
    if (surveyState.isLoading) {
      setTitle(['Loading Survey Details...']);
    }
    else if (surveyState.survey && params.surveyStakeholderId) {
      const surveyStakeholderId = parseInt(params.surveyStakeholderId);
      const edit = survey?.stakeholders?.find(d => d.id === surveyStakeholderId);
      if (edit) {
        setStakeholder(edit);
      }
    }
  }, [params.surveyStakeholderId, survey?.stakeholders, surveyState.isLoading, surveyState.survey]);


  useEffect(() => {
    let titleParts = [surveyState.survey?.name ?? 'Loading', 'Surveys'];

    if (stakeholder?.name) {
      titleParts = [stakeholder?.name ?? 'Loading...', 'Stakeholder Details', ...titleParts];
    }

    setTitle(titleParts);
  }, [stakeholder?.name, surveyState.survey?.name]);

  const questions = survey?.questions?.filter(q => q.type === "MC");
  const parentStakeholder = survey?.stakeholders?.find(s => s.id == stakeholder?.parentSurveyStakeholderId);
  const subStakeholders = survey?.stakeholders?.filter((sub) => sub.parentSurveyStakeholderId === stakeholder?.id);

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">Surveys</li>
        {surveyState.isLoading && <li className="breadcrumb-item">Loading...</li>}
        {surveyState.survey && <li className="breadcrumb-item"><Link to='../..'>{surveyState.survey.name}</Link></li>}
        <li className="breadcrumb-item">Stakeholder Details</li>
        {parentStakeholder && <li className="breadcrumb-item">{parentStakeholder.name}</li>}
        {stakeholder && <li className="breadcrumb-item active">{stakeholder?.name}</li>}
      </ol>
    </div>
    <div className="row">
      <div className="col-md-3">
        <div className="card border-0 mb-4">
          <div className="card-header bg-none h6 p-3 m-0 d-flex align-items-center">
            <div>Stakeholder Details</div>
          </div>
          {stakeholder && <div className="card-body row fw-bold">
            <div className="row mb-15px">
              <div className="col-12 mb-2">
                <h6 className="card-subtitle">Survey</h6>
                <div className="card-text mb-2">
                  {survey?.name}
                </div>
              </div>

              {parentStakeholder && <div className="row mb-15px">
                <label className="form-label col-form-label col-md-12">Parent Stakeholder</label>
                <div className="col-md-9 offset-md-3 align-content-center">
                  <Link to={`/surveys/${survey?.id}/stakeholder/${stakeholder.parentSurveyStakeholderId}/details`}>{parentStakeholder?.name}</Link>
                </div>
              </div>}


              <div className="col-12 mb-2">
                <h6 className="card-subtitle text-muted">Stakeholder Name</h6>
                <div className="card-text mb-2">
                  {stakeholder.name}
                </div>
              </div>

              <div className="col-12 mb-2">
                <h6 className="card-subtitle text-muted">Default Supression Response Count</h6>
                {stakeholder.defaultMinimumResponsesRequired !== undefined && <div className="card-text mb-2">
                  {format.number(stakeholder.defaultMinimumResponsesRequired)}
                </div>}
              </div>
            </div>
          </div>}
          <div className="card-footer text-end">
            <Link relative="path" to='../edit' className="btn btn-sm btn-outline-primary">Edit Stakeholder</Link>
          </div>
        </div>

        {stakeholder && <div className="card border-0 mb-4">
          <div className="card-header bg-none h6 p-3 m-0 d-flex align-items-center">
            <div>Stakeholder Filter</div>
          </div>
          <div className="card-body row fw-bold">
            <div className="row mb-15px">
              <div className="col-auto mb-1">
                <h6 className="card-subtitle text-muted">A response will be attributed to this stakeholder if it matches <strong>any of the choices</strong> selected below.</h6>
                <div className="card-text mb-2 text-end">

                </div>
              </div>

              {stakeholder.values?.map((stakeholderValue) => {
                const question = questions?.find(q => q.id === stakeholderValue.questionId);
                return <div className="col-auto mb-1">
                  <h6 className="card-subtitle text-muted">{stakeholderValue.questionName} {question?.text}</h6>
                  <div className="card-text mb-2 text-end">
                    {stakeholderValue.choiceText}
                  </div>
                </div>;
              })}

            </div>
          </div>
          <div className="card-footer text-end">
            <Link relative="path" to='../edit' className="btn btn-sm btn-outline-primary">Edit Stakeholder Filter</Link>
          </div>
        </div>}
      </div>
      {parentStakeholder === undefined &&<div className="col-md-9">
        {surveyState.error && <div className="alert alert-danger"><strong>Error!</strong> {surveyState.error}</div>}
        <div className="card border-0 mb-4">
          <div className="card-header bg-none h6 p-3 m-0 d-flex align-items-center">
            Sub-Stakeholders
          </div>
          <div className="card-body">
            {(subStakeholders?.length ?? -1) === 0 && <p>
              There are no sub-stakeholders defined below this Stakeholder group.
            </p>}
            {(subStakeholders?.length ?? 0) > 0 && <table className="table table-sm">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>
                    Filter(s)
                  </th>
                  <th>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {subStakeholders?.map((subStakeholder) => <tr key={subStakeholder.id}>
                  <td><Link to={`/surveys/${survey?.id}/stakeholder/${subStakeholder.id}/details`}>{subStakeholder.name}</Link></td>
                  <td>
                    {subStakeholder.values?.map((subValue, index) => <div key={`${subValue.choiceId}-${subValue.questionId}`}>
                      {index > 0 && subStakeholder.operation === BooleanLogicOperationTypes.And && <strong className="me-2">AND</strong>}
                      {index > 0 && subStakeholder.operation === BooleanLogicOperationTypes.Or && <strong className="me-2">OR</strong>}
                      <span>{subValue.questionName}:</span> <span>{subValue.choiceText}</span>
                    </div>)}
                  </td>
                  <td>
                    <Link to={`/surveys/${survey?.id}/stakeholder/${subStakeholder.id}/edit`} className="btn btn-sm">Edit</Link>
                  </td>
                </tr>)}
              </tbody>
              </table>}
          </div>
          {parentStakeholder === undefined && <div className="card-footer">
            <Link relative="path" to='../create' className="btn btn-sm btn-outline-primary">Create a Sub-Stakeholder</Link>
          </div>}
        </div>
      </div>}
    </div>
    <div className="row">
      <div className="col">
        <button type="submit" className="btn btn-success me-2">Save Stakeholder</button>
        <Link to='../..' className="btn btn-primary">Cancel</Link>
      </div>
    </div>

  </div>);
}