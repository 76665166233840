import { useEffect, useState } from "react";
import { setTitle } from "../../util/useDocumentTitle";
import { format } from "../../helpers/format";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fetchSurvey, selectSurvey } from "../../store/surveySlice";
import { setSurveyDetailsTab } from "../../store/uiSlice";
import { QuestionBrowser } from "../../components/survey/questionBrowser";
import Survey from "../../models/survey";
import useDebounce from "../../util/useDebounce";
import Paginated from "../../models/paginated";
import SurveyReport from "../../models/surveyReport";
import { api } from "../../store/api";
import { Pagination } from "../../components/pagination/pagination";
import BooleanLogicOperationTypes from "../../models/booleanLogicOperationTypes";
import SummaryOperationTypes from "../../models/summaryOperationTypes";

export enum SurveyDetailsTabs {
  SharedReports = 1,
  Metrics = 2,
  Questions = 3,
}

interface DetailsRouteParams {
  surveyId?: string;
}

export default function SurveyDetailsScreen() {
  const dispatch = useAppDispatch();
  const ui = useAppSelector(state => state.ui.survey);
  const params = useParams<keyof DetailsRouteParams>();
  const surveyState = useAppSelector(selectSurvey);
  const survey = surveyState.survey;

  useEffect(() => {
    if (params.surveyId) {
      const surveyId = parseInt(params.surveyId);
      if (!surveyState.isLoading && !surveyState.error) {
        if (survey === undefined || survey.id !== surveyId) {
          console.log('Fetching Survey', surveyId, survey);
          dispatch(fetchSurvey(surveyId));
        }
      }
    }
  }, [dispatch, params.surveyId, survey, surveyState.error, surveyState.isLoading]);

  useEffect(() => {
    if (surveyState.isLoading) {
      setTitle(['Loading Survey Details...']);
    }
    else if (surveyState.survey) {
      setTitle([surveyState.survey.name, 'Surveys']);
    }
  }, [surveyState.isLoading, surveyState.survey]);

  const schoolQuestion = survey?.schoolQuestionId && survey?.questions?.find(q => q.id === survey?.schoolQuestionId);

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item"><Link to='..'>Surveys</Link></li>
        {surveyState.isLoading && <li className="breadcrumb-item">Loading...</li>}
        {surveyState.survey && <li className="breadcrumb-item">{surveyState.survey.name}</li>}
        <li className="breadcrumb-item active">Details</li>
      </ol>
    </div>
    <div className="row">
      <div className="col-md-3">
        <div className="card border-0 mb-4">
          <div className="card-header bg-none h6 p-3 m-0 d-flex align-items-center">
            Survey Information
          </div>
          {survey && <div className="card-body row fw-bold">
            <div className="col-auto">
              <h6 className="card-subtitle text-muted">Name</h6>
              <p>{survey?.name}</p>
            </div>

            <div className="col-auto">
              <h6 className="card-subtitle text-muted">Qualtrics ID</h6>
              <div className="card-text mb-2">
                {survey?.qualtricsId}
              </div>
            </div>

            <div className="col-auto">
              <h6 className="card-subtitle text-muted">Last Fetched</h6>
              <div className="card-text mb-2">
                {survey?.structureAsOfUtc && format.date(survey.structureAsOfUtc)}
              </div>
            </div>

            {survey.isActive === false && <div className="col-auto">
              <h6 className="card-subtitle text-muted">Status</h6>
              <p><span className="badge bg-danger">DISABLED</span></p>
            </div>}

            <div className="col-auto">
              <h6 className="card-subtitle text-muted"># Questions</h6>
              <div className="card-text mb-2 text-end">
                {format.number(survey.numberQuestions)}
              </div>
            </div>
            <div className="col-auto">
              <h6 className="card-subtitle text-muted"># Responses</h6>
              <div className="card-text mb-2 text-end">
                {format.number(survey.numberResponses)}
              </div>
            </div>

            <div className="col-auto">
              <h6 className="card-subtitle text-muted">School Question</h6>
              <div className="card-text mb-2">
                {schoolQuestion && <p title={schoolQuestion.text}>{schoolQuestion.name}</p>}
              </div>
            </div>
          </div>}
          <div className="card-footer d-flex flex-row justify-content-end">
            <Link to={'edit'} className="btn btn-sm btn-outline-primary">Edit Survey</Link>
          </div>
        </div>
        <div className="card border-0 mb-4">
          <div className="card-header bg-none h6 p-3 m-0 d-flex align-items-center">
            <i className="fa-solid fa-filter me-2"></i> Stakeholders
          </div>
          {survey?.stakeholders?.length === 0 && <div className="card-body row fw-bold">
            <p>There are no stakeholders defined for the survey {survey!.name}</p>
            <p className="text-warning">Stakeholders are "cohorts" of the responses such as Students, Adults, Parents, Teachers/Staff, Teachers, Admins, etc.
              Each stakeholder includes a "supresion rule" which will indicate the minimum number of responses that must exist in a report in order for the stakeholder to be available (e.g. if less than 10 [teachers], do not show this dimenion).
            </p>
          </div>}
          {(survey?.stakeholders?.length ?? 0) > 0 && <>
            <div className="list-group list-group-flush">
              {survey!.stakeholders!
                .filter((stakeholder) => stakeholder.parentSurveyStakeholderId === undefined)
                .map((d) => <Link to={`stakeholder/${d.id}/details`} className="list-group-item ">
                <div>{d.name}</div>
                <div className="ms-3">
                  {d.values?.map((subValue, index) => <div key={`${subValue.choiceId}-${subValue.questionId}`}>
                    {index > 0 && d.operation === BooleanLogicOperationTypes.And && <strong className="me-2">AND</strong>}
                    {index > 0 && d.operation === BooleanLogicOperationTypes.Or && <strong className="me-2">OR</strong>}
                    <span>{subValue.questionName}:</span> <span>{subValue.choiceText}</span>
                  </div>)}
                  </div>
              </Link>)}
            </div>
          </>}
          <div className="card-footer d-flex flex-row justify-content-end">
            <Link to={'stakeholder/create'} className="btn btn-sm btn-outline-primary">Add Stakeholder</Link>
          </div>
        </div>
      </div>
      <div className="col-md-9">
        {surveyState.error && <div className="alert alert-danger"><strong>Error!</strong> {surveyState.error}</div>}
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button type="button" className={"nav-link " + (ui.detailsTab === SurveyDetailsTabs.SharedReports ? "active" : "")} aria-current="page" onClick={(e) => dispatch(setSurveyDetailsTab(SurveyDetailsTabs.SharedReports))}><i className="fa-duotone fa-solid fa-chart-user me-2"></i> Shared Reports</button>
          </li>
          <li className="nav-item">
            <button type="button" className={"nav-link " + (ui.detailsTab === SurveyDetailsTabs.Metrics ? "active" : "")} aria-current="page" onClick={(e) => dispatch(setSurveyDetailsTab(SurveyDetailsTabs.Metrics))}><i className="fa-duotone fa-solid fa-calculator-simple me-2"></i> Metrics</button>
          </li>
          <li className="nav-item">
            <button type="button" className={"nav-link " + (ui.detailsTab === SurveyDetailsTabs.Questions ? "active" : "")} aria-current="page" onClick={(e) => dispatch(setSurveyDetailsTab(SurveyDetailsTabs.Questions))}><i className="fa-duotone fa-solid fa-clipboard-list-check me-2"></i> Questions</button>
          </li>
        </ul>
        {ui.detailsTab === SurveyDetailsTabs.Questions && <div className="card border-0 mb-4">
          <div className="card-body">
            {survey?.questions && <QuestionBrowser
              questions={survey?.questions}
              selectedQuestionIds={[]}
              editRoute="question/:surveyQuestionId/edit"
            />}
          </div>
        </div>}
        {ui.detailsTab === SurveyDetailsTabs.SharedReports && survey && <SharedReports survey={survey} />}

        {ui.detailsTab === SurveyDetailsTabs.Metrics && <div className="card border-0 mb-4">
          {survey && (survey.metrics?.length ?? 0) === 0 && <div className="card-body row fw-bold">
            <p>There are no metrics defined for the survey {survey.name}</p>
          </div>}
          {(survey?.metrics?.length ?? 0) > 0 && <div className="card-body">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Domain</th>
                  <th>Name</th>
                  <td className="text-end"># Questions</td>
                  <td className="text-end"># Responses</td>
                  <td>Summary</td>
                  <td className="text-end">Actions</td>
                </tr>
              </thead>
              <tbody>

                {survey!.metrics!.map((metric) => <tr key={`metric-${metric.id}`}>
                  <td>{metric.domain}</td>
                  <td><Link to={`metric/${metric.id}/edit`}>{metric.name}</Link></td>
                  <td className="text-end">{metric.questions?.length}</td>
                  <td className="text-end">{metric.countResponses}</td>
                  <td>
                    {metric.summaryOperation === SummaryOperationTypes.None && <>None</>}
                    {metric.summaryOperation === SummaryOperationTypes.Count && <>Count</>}
                    {metric.summaryOperation === SummaryOperationTypes.Average && <>Average</>}
                  </td>
                  <td className="text-end"><Link to={`metric/${metric.id}/edit`} className="btn btn-sm btn-outline-primary">Edit</Link></td>
                </tr>)}
              </tbody>
            </table>
          </div>}
          <div className="card-footer d-flex flex-row justify-content-end">
            <Link to='metric/create' className="btn btn-sm btn-outline-primary">Add Metric</Link>
          </div>
        </div>}
      </div>
    </div>
  </div>);
}

type SharedReportsProps = {
  survey: Survey;
};

function SharedReports(props: SharedReportsProps) {
  const { survey } = props;

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [prevSearchTerm, setPrevSearchTerm] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [paginated, setPaginated] = useState<Paginated<SurveyReport>>();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [sort, setSort] = useState<string>('name');
  const [sortDescending, setSortDescending] = useState(false);

  const debouncedSearchTerm = useDebounce<string>(searchTerm, 800);

  // filters reset pagination
  useEffect(() => {
    setIsLoading(true);
    // load Employee list
    const params = new URLSearchParams({
    });

    if (sort) {
      params.set('sort', sort);
    }
    if (sortDescending === true) {
      params.set('sortAsc', 'false');
    }

    if (prevSearchTerm !== debouncedSearchTerm && page != 1) {
      setPage(1);
      return;
    }
    api.survey(survey.id!).reports.search({ perPage, page, search: debouncedSearchTerm, params: params })
      .then((reports) => {
        setPaginated(reports);
        setPrevSearchTerm(debouncedSearchTerm);
        setIsLoading(false);
        setError(undefined);
      })
      .catch((e: Error) => {
        console.error(e);
        setError("Error fetching records.");
        setIsLoading(false);
      });

    // do not include prevSearchTerm or filters
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, page, perPage, sort, sortDescending]);

  const anyRecords = paginated && paginated.recordsTotal > 0;

  return <div className="card border-0 mb-4">
    <div className="card-body row fw-bold">
      {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
      {anyRecords && <table className="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th className="text-end">Published</th>
            <th className="text-end"># Schools</th>
            <th className="text-end"># Responses</th>
            <th className="text-end">Actions</th>
          </tr>
        </thead>
        <tbody>
          {paginated.records.map((report) => <tr key={`report-${report.id}`}>
            <td><Link to={`report/${report.id}/edit`}>{report.name}</Link></td>
            {report.sharedUtc ? <td className="text-end">{format.date(report.sharedUtc)}</td> : <td className="text-end">never</td>}
            <td className="text-end">{report.countSchools && format.number(report.countSchools)}</td>
            <td className="text-end">{report.countResponses && format.number(report.countResponses)}</td>
            <td className="text-end">
              <Link to={`report/${report.id}/edit`} className="btn btn-sm btn-outline-primary mx-1">Edit</Link>
              <Link to={`report/${report.id}/edit`} className="btn btn-sm btn-outline-secondary mx-1">Publish</Link>
              </td>
          </tr>)}
        </tbody>
      </table>}

      {paginated && paginated.recordsTotal === 0 &&
        <p>There are no shared reports for the survey {survey.name}</p>
      }

      {anyRecords && <Pagination paginated={paginated} setPage={(page) => setPage(page)} setPerPage={(perPage) => setPerPage(perPage)} />}
        
    </div>
    <div className="card-footer d-flex flex-row justify-content-end">
      {survey?.schoolQuestionId
        ? <Link to='report/create' className="btn btn-sm btn-outline-primary">Add Shared Report</Link>
        : <p>You must specify the School question before creating a report</p>}
    </div>
  </div>;
}