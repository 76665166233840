import { useEffect, useRef, useState } from "react";
import { setTitle } from "../../util/useDocumentTitle";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { addUpdateReport, fetchSurvey, selectSurvey } from "../../store/surveySlice";
import { FormProvider, SubmitHandler, useFieldArray, useForm, useFormContext, useWatch } from "react-hook-form";
import { Typeahead, TypeaheadRef } from "react-bootstrap-typeahead";
import SurveyQuestion from "../../models/surveyQuestion";
import { api } from "../../store/api";
import SurveyQuestionChoice from "../../models/surveyQuestionChoice";
import SurveyReport from "../../models/surveyReport";
import SurveyReportSchool from "../../models/surveyReportSchool";
import { format } from "../../helpers/format";
import SurveyStakeholder, { SurveyStakeholderValue } from "../../models/surveyStakeholder";
import Survey from "../../models/survey";
import { SurveyReportStakeholder } from "../../models/surveyReportStakeholder";
import PromptSurveyReportStakeholders from "./report/promptSurveyReportStakeholders";


interface DetailsRouteParams {
  surveyId?: string;
  surveyReportId?: string;
}

const getSchoolIds = (schools?: SurveyReportSchool[]): number[] => {
  return [...(schools?.flatMap((school) => getSchoolIds(school.schools)) ?? []), ...(schools?.filter((school) => school.choiceId).map((school) => school.choiceId!) ?? [])];
};

// const unfoldStakeholders = (stakeholders: SurveyStakeholder[], values: SurveyStakeholderValue[], parentStakeholderId?: number): SurveyStakeholder[] | undefined => {
//   return stakeholders.map((stakeholder) => {
//     return {
//       ...stakeholder,
//       stakeholders: stakeholders.filter(s => s.parentSurveyStakeholderId == stakeholder.id).map()
//     };
//   });
// };


export default function SurveyReportScreen() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams<keyof DetailsRouteParams>();
  const surveyState = useAppSelector(selectSurvey);
  const survey = surveyState.survey;
  const isEditing = params.surveyReportId && true;

  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [surveyReportStakeholders, setSurveyReportStakeholders] = useState<SurveyReportStakeholder[]>([]);

  useEffect(() => {
    if (params.surveyId) {
      const surveyId = parseInt(params.surveyId);
      if (!surveyState.isLoading) {
        if (survey === undefined || survey.id !== surveyId) {
          console.log('Fetching Survey', surveyId, survey);
          dispatch(fetchSurvey(surveyId));
        }
      }
    }
  }, [dispatch, params.surveyId, survey, surveyState.isLoading]);

  const form = useForm<SurveyReport>();
  const { register, handleSubmit, reset, formState: { errors }, watch, control } = form;
  const watchSchools = watch('schools');
  const { fields: stakeholders } = useFieldArray({
    control,
    name: "stakeholders",
  });
  const hasSchoolSpecified = (reportSchool: SurveyReportSchool): boolean => {
    if (reportSchool.choiceId) {
      return true;
    }

    return reportSchool.schools?.find((school) => hasSchoolSpecified(school)) !== undefined;
  }

  const onToggleSurveyStakeholder = (stakeholder: SurveyStakeholder) => {
    const existing = surveyReportStakeholders.find((s) => s.id === stakeholder.id) ?? {
      ...stakeholder,
      isEnabled: false,
      minimumResponsesRequired: stakeholder.defaultMinimumResponsesRequired,
    };

    setSurveyReportStakeholders((v) => [
      ...v.filter((val) => val.id !== stakeholder.id),
      {
        ...existing,
        isEnabled: !existing.isEnabled,
      },
    ]);
  }
  const onChangeSurveyStakeholder = (stakeholder: SurveyStakeholder, value: string) => {
    const existing = surveyReportStakeholders.find((s) => s.id === stakeholder.id) ?? {
      ...stakeholder,
      minimumResponsesRequired: stakeholder.defaultMinimumResponsesRequired,
    };

    setSurveyReportStakeholders((v) => [
      ...v.filter((val) => val.id !== stakeholder.id),
      {
        ...existing,
        minimumResponsesRequired: parseInt(value) ?? existing.minimumResponsesRequired,
      },
    ]);
  }

  const onSubmit: SubmitHandler<SurveyReport> = data => {
    console.warn('onSubmit', data);
    const request: SurveyReport = {
      ...data,
      surveyId: survey!.id,
      stakeholders: surveyReportStakeholders,
    };

    const hasSchools = watchSchools?.find((school) => hasSchoolSpecified(school)) !== undefined;

    if (!hasSchools) {
      setError('You must specify at least one school');
      return;
    }


    setError(undefined);
    setIsSaving(true);
    if (isEditing) {
      api.survey(survey!.id!).reports.update(request).then((result) => {
        dispatch(addUpdateReport(result));
        navigate('../..');
      })
        .catch((reason) => {
          setError("Unable to save report: " + reason);
          setIsSaving(false);
        });
    }
    else {
      // create
      api.survey(survey!.id!).reports.create(request).then((result) => {
        dispatch(addUpdateReport(result));
        navigate('../..');
      })
        .catch((reason) => {
          setError("Unable to save report: " + reason);
          setIsSaving(false);
        });
    }
  };


  useEffect(() => {
    if (surveyState.isLoading) {
      setTitle(['Loading Survey Details...']);
    }
    else if (surveyState.survey) {
      let titleParts = [surveyState.survey.name, 'Surveys'];
      if (isEditing) {
        const surveyReportId = parseInt(params.surveyReportId);
        // const edit = survey?.stakeholders?.find(d => d.id === surveyStakeholderId);
        setIsLoading(true);
        api.survey(survey!.id!).reports.get(surveyReportId)
          .then((result) => {
            reset({
              ...result,
              stakeholders: survey!.stakeholders?.map((s) => {
                const existing = result.stakeholders?.find((existing) => s.id === existing.id);
                return {
                  ...s,
                  surveyStakeholderId: s.id,
                  minimumResponsesRequired: existing?.minimumResponsesRequired ?? s.defaultMinimumResponsesRequired,
                  isEnabled: existing?.isEnabled ?? false,
                };
              }),
            });
            setSurveyReportStakeholders(result.stakeholders ?? []);
            setIsLoading(false);
          })
          .catch((reason) => {
            console.error(reason);
            setError(`Unable to load report #${params.surveyReportId}`);
            setIsLoading(false);
          });

        titleParts = ['Edit Shared Report', ...titleParts];
        //reset(edit);
      }
      else {
        titleParts = ['Create Shared Report', ...titleParts];
        // TODO stakeholders
        // reset({
        //   stakeholders: survey!.stakeholders
        //     ?.filter(s => s.parentSurveyStakeholderId === undefined)
        //     ?.map((s) => {
        //       return {
        //         ...s,
        //         surveyStakeholderId: s.id,
        //         minimumResponsesRequired: s.defaultMinimumResponsesRequired,
        //         isEnabled: true,
        //         stakeholders:
        //     }
        //     }),
        // });
      }
      setTitle(titleParts);
    }
  }, [isEditing, params.surveyReportId, reset, survey, surveyState.isLoading, surveyState.survey]);

  const schoolQuestion = survey?.questions?.find(q => q.id === survey?.schoolQuestionId);

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">Surveys</li>
        {surveyState.isLoading && <li className="breadcrumb-item">Loading...</li>}
        {surveyState.survey && <li className="breadcrumb-item"><Link to='../..'>{surveyState.survey.name}</Link></li>}
        {isEditing ? <li className="breadcrumb-item active">Edit Shared Report</li> : <li className="breadcrumb-item active">Create Shared Report</li>}
      </ol>
    </div>
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-xl-3">
            <div className="card border-0 mb-4">
              <div className="card-header bg-none h6 p-3 m-0 d-flex align-items-center">
                Shared Report for {survey && <i>{survey.name}</i>}
              </div>
              {survey && <div className="card-body row fw-bold">
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-12">Report Name</label>
                  <div className="col-12">
                    <input type="text" className={"form-control mb-5px " + (errors.name ? 'is-invalid' : '')} {...register("name", { required: true })} autoComplete="off" />
                    {errors.name && <div className="invalid-feedback">This field is required</div>}
                  </div>
                </div>
              </div>}
            </div>

            {(survey?.stakeholders?.length ?? 0) > 0 && <div className="card border-0 mb-4">
              <div className="card-header bg-none h6 p-3 m-0 d-flex align-items-center">
                <i className="fa-solid fa-filter me-2"></i> Stakeholders
              </div>
              {survey?.stakeholders?.length === 0 && <div className="card-body row fw-bold">
                <p>There are no stakeholders defined for the survey {survey!.name}</p>
              </div>}
              {(survey?.stakeholders?.length ?? 0) > 0 && <>
                <div className="card-body">
                  <p>
                    <strong>Supression rules:</strong> Define the minimum number of responses needed in order to allow this report
                    to show report elements for each stakeholder.
                  </p>
                  {survey?.stakeholders && <PromptSurveyReportStakeholders
                    key='promptSurveyStakeholders'
                    stakeholders={survey.stakeholders}
                    values={surveyReportStakeholders}
                    toggle={onToggleSurveyStakeholder}
                    change={onChangeSurveyStakeholder}
                  />}
                  {/* {stakeholders!
                    .map((field, index) => {
                      const surveyStakeholder = survey?.stakeholders?.find((s) => s.id == field.surveyStakeholderId);

                      return <div key={field.id} className="list-group-item d-flex justify-content-between align-items-center">
                        <div className="form-check">
                          <input type="checkbox" {...register(`stakeholders.${index}.isEnabled` as const)} className="form-check-input" />
                          { }
                          {surveyStakeholder?.name}
                        </div>
                        <div><input type="number" className="form-control form-control-sm text-end" {...register(`stakeholders.${index}.minimumResponsesRequired` as const)} /></div>
                      </div>;
                    })} */}
                </div>
              </>}
            </div>}
          </div>
          <div className="col-xl-9">
            {surveyState.error && <div className="alert alert-danger"><strong>Error!</strong> {surveyState.error}</div>}
            <div className="card border-0 mb-4">
              <div className="card-header bg-none h6 p-3 m-0 d-flex align-items-center">
                Report System(s)/School(s)
              </div>
              <div className="card-body">
                {isLoading && <span>Fetching existing report details...</span>}
                {(watchSchools?.length ?? 0) > 0 && <div className="row border-bottom mb-1">
                  <div className="col-3 fw-bold">Parent System</div>
                  <div className="col-3 fw-bold">School/System</div>
                  <div className="col-2 fw-bold">Contact Person</div>
                  <div className="col-2 fw-bold">Contact Role</div>
                  <div className="col-2 fw-bold">Contact Email</div>
                </div>}
                {survey?.id && schoolQuestion && <RenderReports surveyId={survey?.id} schoolQuestion={schoolQuestion} prefix="" indent={0} reportSchools={watchSchools ?? []} />}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {error && <div className="border-1 alert alert-danger"><strong>Error</strong> {error}</div>}
            {isSaving ? <button type="submit" className="btn btn-success me-2" disabled={isSaving}>Saving Report...</button> : <button type="submit" className="btn btn-success me-2" disabled={isSaving}>Save Report</button>}
            <Link to='../..' className="btn btn-primary">Cancel</Link>
          </div>
        </div>
      </form>
    </FormProvider>

  </div>);

}



type RenderReportProps = {
  surveyId: number;
  schoolQuestion: SurveyQuestion;
  prefix: string;
  // roles: string[];
  indent: number;
  parentSchool?: SurveyReportSchool;
  // selectedChoiceIds: number[];
  reportSchools: SurveyReportSchool[];
};

function RenderReports(props: RenderReportProps) {
  const { prefix, schoolQuestion, indent, parentSchool, reportSchools, surveyId } = props;

  const [promptAdd, setPromptAdd] = useState<boolean>();

  // const contactRolePath = `${prefix}contactRole` as 'contactRole';
  // const contactNamePath = `${prefix}contactName` as 'contactName';
  // const contactEmailPath = `${prefix}contactEmail` as 'contactEmail';
  const schoolsArrayInputPath = `${prefix}schools` as 'schools';
  const { control, register } = useFormContext<SurveyReportSchool | SurveyReport>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: schoolsArrayInputPath,
    keyName: "id"
  });


  const watchSchools = useWatch({
    control: control,
    name: schoolsArrayInputPath,
  });

  useEffect(() => {
    // ((fields.length === 0 && parentSchool === undefined) || (parentSchool?.name))
    if ((watchSchools?.length ?? 0) === 0) {
      setPromptAdd(true);
    }
  }, [watchSchools]);

  return <>
    {fields.map((field, index) => {
      //const question = questions?.find(q => q.id === field.questionId);
      const newPrefix = `${schoolsArrayInputPath}.${index}.`;
      //if (field.choiceId) {
      // School
      const parentOrg = parentSchool?.choice?.choiceText ?? parentSchool?.name;
      return <><div className={"row border-bottom pb-1 mb-1 align-items-center " + (index % 2 === 0 ? '' : '')}>
        <div className="col-lg-2">{parentOrg ? <span>{parentOrg}</span> : <i>No parent system</i>}</div>
        <div className="col-lg-4">
          <div className="input-group">
            {field.choice === undefined
              ? <><button type="button" className="btn btn-sm btn-outline-primary" disabled title="School / System"><i className="fa-fw fa-solid fa-building"></i></button><input className="form-control" type="text" placeholder="System name" {...register(`${schoolsArrayInputPath}.${index}.name` as 'name')} /></>
              : <>
                <span className="input-group-text"><i className="fa-solid fa-school"></i></span>
                <input className="form-control readonly" readOnly value={field.choice?.choiceText} />
                {field.choiceId && <ResponseCount surveyId={surveyId} choiceId={field.choiceId} />}
              </>}
            <button type="button" className="btn btn-sm btn-outline-danger" disabled={(watchSchools?.at(index)?.schools?.length ?? 0) > 0} onClick={() => remove(index)}><i className="fa-solid fa-trash"></i></button>
          </div>

        </div>
        <div className="col-lg-2">
          <input
            type="text"
            className="form-control"
            placeholder="Contact name"
            {...register(`${schoolsArrayInputPath}.${index}.contactName` as 'contactName')} />
        </div>
        <div className="col-lg-2">
          <input
            type="text"
            className="form-control"
            placeholder="Contact role"
            {...register(`${schoolsArrayInputPath}.${index}.contactRole` as 'contactRole')} />
          {/* <Typeahead
            id={`${schoolsArrayInputPath}.${index}.contactRole`}
            allowNew={true}
            placeholder="Contact role"
            {...register(`${schoolsArrayInputPath}.${index}.contactRole` as 'contactRole')}
            onChange={(selected) => {
            }}
            options={roles}
          /> */}
        </div>
        <div className="col-lg-2">
          <input
            type="text"
            className="form-control"
            placeholder="Contact email"
            {...register(`${schoolsArrayInputPath}.${index}.contactEmail` as 'contactEmail')}
          />
        </div>
      </div>
        {schoolQuestion && <RenderReports surveyId={surveyId} key={`${newPrefix}_subfields`} schoolQuestion={schoolQuestion} prefix={newPrefix} indent={indent + 1} parentSchool={watchSchools?.at(index)} reportSchools={reportSchools} />}
      </>;
      //}

    })}

    {/* {(parentSchool?.choiceId === undefined || fields.length === 0) &&  <RenderAddField schoolQuestion={schoolQuestion} canAddDistrict={true} append={append} indent={indent + 1} />} */}
    {((fields.length === 0 && parentSchool === undefined) || (parentSchool?.name && parentSchool?.choiceId === undefined)) && <div className="row border-bottom mb-1 align-items-center">
      <div className="col-lg-2">{parentSchool?.name}</div>
      <div className="col-lg-6 d-flex flex-row justify-content-between">
        <RenderAddField schoolQuestion={schoolQuestion} canAddDistrict={true} append={append} indent={indent + 1} reportSchools={reportSchools} />
      </div>
    </div>}
  </>;
}
type RenderAddFieldProps = {
  schoolQuestion: SurveyQuestion;
  canAddDistrict: boolean;
  append: (school: SurveyReportSchool) => void;
  indent: number;
  // selectedChoiceIds: number[];
  reportSchools: SurveyReportSchool[];
};

function RenderAddField(props: RenderAddFieldProps) {
  const { schoolQuestion, canAddDistrict, append, indent, reportSchools } = props;
  const [addSchool, setAddSchool] = useState<boolean>();
  const addQuestionChoiceRef = useRef<TypeaheadRef>(null);

  return <div className="d-flex flex-grow-1 w-100">
    {addSchool && schoolQuestion && <div className="input-group w-100">
      <span className="input-group-text">School:</span>
      <Typeahead
        id={`select_school-${indent}`}
        placeholder="Select a school"
        ref={addQuestionChoiceRef}
        options={schoolQuestion?.choices?.filter((choice) => !getSchoolIds(reportSchools).includes(choice.id)) ?? []}
        //options={schoolQuestion.choices ?? []}
        labelKey='choiceText'
        onChange={(selected) => {
          const item = selected as SurveyQuestionChoice[];
          if (item.length > 0) {
            const q = item[0];
            append({
              choiceId: q.id,
              choice: q,
            });
            //addQuestionRef?.current?.clear();
            addQuestionChoiceRef?.current?.clear();
            setAddSchool(undefined);
          }
        }}
      //className={" " + (errors?.values?.[index]?.choiceId ? "is-invalid" : "")}
      />
      <button type="button" className="btn btn-sm btn-outline-danger" onClick={() => setAddSchool(undefined)}><i className="fa-solid fa-times"></i></button>
    </div>}
    {addSchool === false && <span>ADD SYSTEM</span>}
    {addSchool === undefined && <button type="button" className="btn btn-sm" onClick={() => setAddSchool(true)}><i className="fa-solid fa-plus"></i> Add School</button>}
    {canAddDistrict && addSchool === undefined && <button type="button" className="btn btn-sm" onClick={() => append({ name: 'New system' })}><i className="fa-solid fa-plus"></i> Add System</button>}

  </div>;
}

type ResponseCountProps = {
  surveyId: number;
  choiceId: number;
}
function ResponseCount(props: ResponseCountProps) {
  const [count, setCount] = useState<number>();

  useEffect(() => {
    api.survey(props.surveyId).responses.count({ choiceId: props.choiceId }).then(setCount);
  }, [props.choiceId, props.surveyId]);

  if (count) {
    return <span className="input-group-text" title="Responses">{format.number(count)}</span>;
  }
  return <></>;
}