import { useEffect, useRef, useState } from "react";
import { SurveyReportDataDomainComparison } from "../../../models/surveyReportData";
import HighchartsReact from "highcharts-react-official";
import Highcharts, { Point } from "highcharts";
import { SurveyReportStakeholder } from "../../../models/surveyReportStakeholder";
import StakeholderBreadcrumb from "../../../components/survey/stakeholderBreadcrumb";
import { api } from "../../../store/api";
import Survey from "../../../models/survey";
import SurveyReport from "../../../models/surveyReport";
import SurveyReportSchool from "../../../models/surveyReportSchool";

type DomainComparisonComponentProps = {
  survey: Survey;
  report: SurveyReport;
  reportSchool: SurveyReportSchool;
  stakeholders: SurveyReportStakeholder[];
  comparison: SurveyReportDataDomainComparison;
}

export default function DomainComparisonComponent(props: DomainComparisonComponentProps) {
  const { survey, report, reportSchool, comparison, stakeholders } = props;

  const [filterStakeholder, setFilterStakeholder] = useState<SurveyReportStakeholder>();

  return <div className="card mb-2">
    <div className="card-header d-flex flex-row justify-content-between">
      <h4>{comparison.subDomain}</h4>
      <div>
        <StakeholderBreadcrumb stakeholders={stakeholders} filterStakeholder={filterStakeholder} setFilterStakeholder={setFilterStakeholder} />
      </div>
    </div>
    <div className="card-body">
      <ComparisonVisualization survey={survey} report={report} reportSchool={reportSchool} stakeholder={filterStakeholder} comparison={comparison} />
    </div>
  </div>;
}


type ComparisonVisualizationProps = {
  survey: Survey;
  report: SurveyReport;
  reportSchool: SurveyReportSchool;
  stakeholder?: SurveyReportStakeholder;
  comparison: SurveyReportDataDomainComparison;
}

function ComparisonVisualization(props: ComparisonVisualizationProps) {
  const { survey, report, reportSchool, stakeholder, comparison } = props;
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setChartOptions(undefined);
    api.survey(survey.id).report(report.id).school(reportSchool.id!)
      .comparison({
        ...comparison,
        school: reportSchool.id!,
        stakeholder: stakeholder?.surveyStakeholderId,
      })
      .then((data) => {
        setChartOptions({
          chart: {
            type: 'bar',
            //type: 'line',
          },
          title: {
            text: comparison.label ?? '',
          },
          xAxis: {
            //type: 'category'
            categories: data.categories,
            // [
            //   'Academic orientation',
            //   'Safety',
            //   'Unique supports for learning',
            //   'Welcoming and inclusive environment',
            //   'Dedicated teachers and staff',
            //   'Character formation',
            //   'Focus on building socialization skills',
            //   'Physical environment',
            //   'Faith formation',
            // ]
          },
          yAxis: [
            {
              labels: {
                style: {
                }
              },
              min: 0,
              max: 100,
              title: {
                text: '% Respondants Selected'
              },
            }],
          plotOptions: {
            series: {
              dataLabels: {
                enabled: false,
                format: '{y:.2f} %'
              }
            }
          },
          series: Object.keys(data.questionCategoryResponseCounts).map((seriesName) => {
            return {
              type: 'bar',
              name: seriesName,
              tooltip: {
                valueDecimals: 2,
                valueSuffix: '%',
                // pointFormatter: function (this: Point): string {
                //   if (this.y !== undefined) {
                //     // const resource = data.resources[this.x];
                //     // const topic = heatmapDrilldownData.subTags[this.y];
                //     // const domain = tagForPoint;
                //     return `testing!`;
                //   }
                //   return '';
                // },
              },
              data: data.categories.map(c => {
                console.log('found', );
                const responseCountForCategory = data.questionCategoryResponseCounts[seriesName][c] ?? 0;
                return responseCountForCategory / data.responseCount * 100.0;
              }),
            };
          }),
        });
        setIsLoading(false);
      })
      .catch((reason) => {
        //setError("Unable to load report schools/systems: " + reason);
        setIsLoading(false);
      });

  }, [comparison, report.id, reportSchool.id, stakeholder?.name, stakeholder?.surveyStakeholderId, survey.id]);

  return <>
    {isLoading && <div className="alert text-center"><i className="fa-solid fa-spin fa-spinner"></i> <strong>Loading comparison</strong></div>}
    {chartOptions && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartComponentRef} />}
  </>;
}
