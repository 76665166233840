import { useEffect, useState } from "react";
import { setTitle } from "../../util/useDocumentTitle";
import { api } from "../../store/api";
import { Panel, PanelBody } from "../../components/panel/panel";
import { format } from "../../helpers/format";
import { Link } from "react-router-dom";
import Survey from "../../models/survey";



export default function SurveysScreen() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [records, setRecords] = useState<Survey[]>();

  useEffect(() => {
    setTitle(['Surveys']);
    setIsLoading(true);
    api.surveys.list().then((surveys) => {
      setRecords(surveys);
      setIsLoading(false);
    })
      .catch((e: Error) => {
        console.error(e);
        setError("Unable to load surveys: " + e);
        setIsLoading(false);
      });

  }, []);

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">Surveys</li>
        <li className="breadcrumb-item active">List</li>
      </ol>
    </div>
    <Panel className="card border-0" isLoading={isLoading}>
      <PanelBody className="card-body">
        {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
        {records && records.length === 0 && <p className="text-center">
          There are no Surveys available to manage.
        </p>}
        {records && records.length > 0 && <div className="overflow-x-auto">
          <table className="table table-hover table-panel text-nowrap align-middle mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Last Fetched</th>
                <th># Questions</th>
                <th># Responses</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {records.map((survey) => <tr key={survey.id}>
                <td><Link to={`${survey.id}`}>{survey.qualtricsId}</Link></td>
                <td>
                  <Link to={`${survey.id}`}>{survey.name}</Link> {survey.isActive === false && <span className="badge bg-danger">DISABLED</span>}

                </td>
                <td>{survey.structureAsOfUtc && format.date(survey.structureAsOfUtc)}</td>
                <td>{format.number(survey.numberQuestions ?? 0)}</td>
                <td>{format.number(survey.numberResponses ?? 0)}</td>
                <td>
                  <Link to={`${survey.id}`} className="btn btn-sm btn-outline-primary">View</Link>
                </td>
              </tr>)}
            </tbody>
          </table>
        </div>}
      </PanelBody>
    </Panel>

  </div>);
}