import { useEffect, useState } from "react";
import { setTitle } from "../../util/useDocumentTitle";
import { format } from "../../helpers/format";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fetchSurvey, selectSurvey, updateSurvey } from "../../store/surveySlice";
import { setSurveyDetailsTab } from "../../store/uiSlice";
import { QuestionBrowser } from "../../components/survey/questionBrowser";
import { FormProvider, SubmitHandler, useForm, useWatch } from "react-hook-form";
import Survey from "../../models/survey";
import { Typeahead } from "react-bootstrap-typeahead";
import SurveyQuestion from "../../models/surveyQuestion";
import { api } from "../../store/api";

interface EditRouteParams {
  surveyId?: string;
}

export default function SurveyEditScreen() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  //const ui = useAppSelector(state => state.ui.survey);
  const params = useParams<keyof EditRouteParams>();
  const surveyState = useAppSelector(selectSurvey);
  const survey = surveyState.survey;

  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string>();
  const [schoolQuestion, setSchoolQuestion] = useState<SurveyQuestion>();

  const form = useForm<Survey>();
  const { register, handleSubmit, setValue, reset, formState: { errors }, control } = form;
  const onSubmit: SubmitHandler<Survey> = data => {
    // console.log(data);
    // console.log('selectedQuestionIds', selectedQuestionIds);
    const request: Survey = {
      ...data,
      //questions: surveyState.survey?.questions?.filter(q => selectedQuestionIds.includes(q.id)),
    };

    console.log(request);
    setError(undefined);
    setIsSaving(true);
    api.survey(survey!.id).update(request)
      .then((updated) => {
        setIsSaving(false);
        //setError(undefined);
        //const path = isEditing ? '' : '#created';
        //navigate(generatePath(ReviewDetailsRoute, { id: `${updated.id}` }) + path);
        dispatch(updateSurvey(updated));
        navigate('..');
      })
      .catch((reason) => {
        setError("Unable to save survey: " + reason);
        setIsSaving(false);
      });
  };

  useEffect(() => {
    if (params.surveyId) {
      const surveyId = parseInt(params.surveyId);
      if (!surveyState.isLoading && !surveyState.error) {
        if (survey === undefined || survey.id !== surveyId) {
          console.log('Fetching Survey', surveyId, survey);
          dispatch(fetchSurvey(surveyId));
        }
      }
    }
  }, [dispatch, params.surveyId, survey, surveyState.error, surveyState.isLoading]);



  const watchIsActive = useWatch({
    control: control,
    name: 'isActive',
  });

  const watchSchoolQuestion = useWatch({
    control: control,
    name: 'schoolQuestionId',
  });

  const questions = survey?.questions?.filter(q => q.type === "MC");

  useEffect(() => {
    setSchoolQuestion(questions?.find(q => q.id === watchSchoolQuestion));
  }, [questions, watchSchoolQuestion]);

  useEffect(() => {
    if (surveyState.isLoading) {
      setTitle(['Loading Survey Details...']);
    }
    else if (surveyState.survey) {
      setTitle([surveyState.survey.name, 'Edit Survey', 'Surveys']);
      reset(surveyState.survey);
    }
  }, [reset, surveyState.isLoading, surveyState.survey]);

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item"><Link to='..'>Surveys</Link></li>
        {surveyState.isLoading && <li className="breadcrumb-item">Loading...</li>}
        {surveyState.survey && <li className="breadcrumb-item"><Link to='..'>{surveyState.survey.name}</Link></li>}
        <li className="breadcrumb-item active">Edit</li>
      </ol>
    </div>
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>

        <div className="row">
          <div className="col-md-3">
            <div className="card border-0 mb-4">
              <div className="card-header bg-none h6 p-3 m-0 d-flex align-items-center">
                Survey Information
              </div>
              {survey && <div className="card-body row fw-bold">
                <div className="col-auto">
                  <h6 className="card-subtitle text-muted">Name</h6>
                  <p>{survey?.name}</p>
                </div>

                <div className="col-auto">
                  <h6 className="card-subtitle text-muted">Qualtrics ID</h6>
                  <div className="card-text mb-2">
                    {survey?.qualtricsId}
                  </div>
                </div>

                <div className="col-auto">
                  <h6 className="card-subtitle text-muted">Last Fetched</h6>
                  <div className="card-text mb-2">
                    {survey?.structureAsOfUtc && format.date(survey.structureAsOfUtc)}
                  </div>
                </div>

                <div className="col-auto">
                  <h6 className="card-subtitle text-muted"># Questions</h6>
                  <div className="card-text mb-2 text-end">
                    {format.number(survey.numberQuestions)}
                  </div>
                </div>
                <div className="col-auto">
                  <h6 className="card-subtitle text-muted"># Responses</h6>
                  <div className="card-text mb-2 text-end">
                    {format.number(survey.numberResponses)}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-check form-switch mb-2">
                    <input className="form-check-input" type="checkbox" id="isActive" {...register("isActive")} />
                    <label className="form-check-label" htmlFor="isActive"><label className="form-check-label" htmlFor="isActive">{Boolean(watchIsActive) ? 'Active' : 'Disabled'}</label></label>
                  </div>
                </div>
              </div>}
            </div>


          </div>
          <div className="col-md-9">
            {surveyState.error && <div className="alert alert-danger"><strong>Error!</strong> {surveyState.error}</div>}
            <div className="card border-0 mb-4">
              <div className="card-header bg-none h6 p-3 m-0 d-flex align-items-center">
                School Selection Question
              </div>
              {survey && <div className="card-body row fw-bold">

                <div className="col-12">
                  <p>Please identify which question in the survey the respondent uses to select their School.</p>

                  {questions && <Typeahead
                    //ref={addQuestionRef}
                    emptyLabel={'Select a question from the survey...'}
                    multiple={false}
                    //labelKey="name"
                    labelKey={(option) => {
                      const question = option as SurveyQuestion;
                      return `${question.name}: ${question.text}`;
                    }}
                    // labelKey={(option) => {
                    //                     const projectGrade = option as ProjectGrade;
                    //                     return labels.grades[projectGrade.grade];
                    //                   }}
                    //filterBy={['name']}
                    //selected={grade ? [grade] : undefined}
                    selected={questions.filter(q => q.id === watchSchoolQuestion)}
                    clearButton={true}
                    options={questions?.map(q => {
                      return {
                        ...q,
                        label: `${q.name} - ${q.text}`,
                      };
                    })}
                    onChange={(selected) => {
                      const item = selected as SurveyQuestion[];
                      if (item.length > 0) {
                        const q = item[0];
                        setValue('schoolQuestionId', q.id);
                        //setAddQuestion(q);
                        // append({
                        //   questionId: q.id,
                        //   questionName: `${q.name} - ${q.text}`,
                        // });
                        // addQuestionRef?.current?.clear();
                      }
                    }}
                  />}
                </div>
              </div>}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {error && <div className="alert alert-danger"><strong>Error</strong> {error}</div>}
            {isSaving === false && <button type="submit" className="btn btn-success">Save Changes</button>}
            {isSaving === true && <button type="submit" className="btn btn-success" disabled>Saving Changes...</button>}
          </div>
        </div>
      </form>
    </FormProvider>
  </div>);
}