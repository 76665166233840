import { useState } from "react";
import { SurveyReportStakeholder } from "../../models/surveyReportStakeholder";

type StakeholderBreadcrumbProps = {
  stakeholders: SurveyReportStakeholder[];
  filterStakeholder?: SurveyReportStakeholder;
  setFilterStakeholder: (val: SurveyReportStakeholder | undefined) => void;
}
export default function StakeholderBreadcrumb(props: StakeholderBreadcrumbProps) {
  const { stakeholders, filterStakeholder, setFilterStakeholder } = props;
  const [showStakeholders, setShowStakeholders] = useState(false);

  const onClickAll = () => {
    if (filterStakeholder) {
      //setFilterStakeholder(undefined);
    }
    else {
      setShowStakeholders(true);
    }
  }

  return <ol className="breadcrumb align-items-middle">
    {/* {(filterStakeholder || !showStakeholders) && <li className="breadcrumb-item"><button className="btn btn-link p-0" onClick={onClickAll}>All Stakeholders</button></li>} */}
    {/* {filterStakeholderParent && <li className="breadcrumb-item"><button className="btn btn-link p-0" onClick={() => setFilterStakeholder(filterStakeholderParent)}>{filterStakeholderParent.surveyStakeholder!.name}</button></li>} */}
    {/* {filterStakeholder && <StakeholderBreadcrumbItem stakeholders={stakeholders} stakeholder={filterStakeholder} setFilterStakeholder={setFilterStakeholder} />} */}
    <StakeholderBreadcrumbItem stakeholders={stakeholders} stakeholder={filterStakeholder} setFilterStakeholder={setFilterStakeholder} />
  </ol>;
}


type StakeholderBreadcrumbItemProps = {
  stakeholders: SurveyReportStakeholder[];
  stakeholder?: SurveyReportStakeholder;
  setFilterStakeholder: (val: SurveyReportStakeholder | undefined) => void;
  includeSubstakeholders?: boolean;
}
function StakeholderBreadcrumbItem(props: StakeholderBreadcrumbItemProps) {
  const { stakeholders, stakeholder, setFilterStakeholder, includeSubstakeholders } = props;
  const sibilingStakeholders = stakeholders.filter(s => stakeholder === undefined ? s.surveyStakeholder?.parentSurveyStakeholderId === undefined : s.surveyStakeholder?.parentSurveyStakeholderId === stakeholder.surveyStakeholder?.parentSurveyStakeholderId);
  const parentStakeholder = stakeholders.find(s => stakeholder === undefined ? false : s.id === stakeholder?.surveyStakeholder?.parentSurveyStakeholderId);
  const subStakeholders = stakeholder === undefined ? [] : stakeholders.filter(s => s.surveyStakeholder?.parentSurveyStakeholderId === stakeholder?.surveyStakeholderId);
  const unavailable = <span className="badge bg-warning">UNAVAILABLE</span>;
  return <>
    {parentStakeholder && <StakeholderBreadcrumbItem key={`ps_${parentStakeholder.surveyStakeholderId}`} stakeholders={stakeholders} stakeholder={parentStakeholder} setFilterStakeholder={setFilterStakeholder} includeSubstakeholders={false} />}
    {/* {stakeholder && <li className="breadcrumb-item"><button className="btn btn-link p-0">{stakeholder.surveyStakeholder?.name}</button></li>} */}
    <li className="breadcrumb-item d-flex flex-row">
      <div>
        {stakeholder
          ? <button className="btn btn-white dropdown-toggle" type="button" data-bs-toggle="dropdown">{stakeholder?.surveyStakeholder?.name} <b className="caret"></b></button>
          : <button className="btn btn-white dropdown-toggle" type="button" data-bs-toggle="dropdown">All Stakeholders <b className="caret"></b></button>}
        <div className="dropdown-menu">
          {parentStakeholder === undefined && <button className="dropdown-item" onClick={() => setFilterStakeholder(undefined)}>All Stakeholders</button>}
          {sibilingStakeholders.map(s => s.isSupressed
            ? <button className="dropdown-item disabled">{s.surveyStakeholder?.name}</button>
            : <button className="dropdown-item" onClick={() => setFilterStakeholder(s)}>{s.surveyStakeholder?.name}</button>
          )}
        </div>
      </div>
    </li>
    {(includeSubstakeholders ?? true) && subStakeholders.length > 0 && <li className="breadcrumb-item d-flex flex-row">
      <div>
        <button className="btn btn-white dropdown-toggle" type="button" data-bs-toggle="dropdown">All {stakeholder?.surveyStakeholder?.name} <b className="caret"></b></button>
        <div className="dropdown-menu">
          <button className="dropdown-item" onClick={() => setFilterStakeholder(stakeholder)}>All {stakeholder?.surveyStakeholder?.name}</button>
          {subStakeholders.map(s => s.isSupressed
            ? <button className="dropdown-item disabled">{s.surveyStakeholder?.name}</button>
            : <button className="dropdown-item" onClick={() => setFilterStakeholder(s)}>{s.surveyStakeholder?.name}</button>)}
        </div>
      </div>
    </li>}
    {/* {subStakeholders && <StakeholderBreadcrumbItem stakeholders={subStakeholders} setFilterStakeholder={setFilterStakeholder} />} */}
  </>;
}